import { createRouter, createWebHashHistory } from 'vue-router';

import LayoutBasicRouter from '@/layout/layout-basic-router.vue';
import LayoutBlankRouter from '@/layout/layout-blank-router.vue';

import { PermissionKey } from '@/constants/permission-key.js';
import { AgencyType } from '@/constants/option-value-agency.js';

import i18n from '@/locales/locales.js';

export const allRoutes = [
  {
    path: '/login',
    name: 'LoginRouter',
    component: () => import('@/views/login/login-router.vue'),
    meta: {
      title: '登录',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordRouter',
    component: () => import('@/views/reset-password/reset-password-router.vue'),
    meta: {
      title: '重置密码',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401'),
    meta: {
      title: '401',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      title: '403',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      title: '404',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/snapshot-details',
    name: 'SnapshotDetails',
    component: () => import('@/views/snapshot/SnapshotDetails.vue'),
    meta: {
      title: '快照详情',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/app-mapbox-choose',
    name: 'AppMapboxChoose',
    component: () => import('@/views/map/app-mapbox-choose.vue'),
    meta: {
      title: 'mapbox地图',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/app-map-poimarker',
    name: '',
    component: () => import('@/views/map/app-map-poimarker.vue'),
    meta: {
      title: '查看位置',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/print',
    name: 'Print',
    component: () => import('@/views/print'),
    meta: {
      title: '打印页面',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/station-monthly-statement-confirmation',
    name: 'StationMonthlyStatementConfirmation',
    component: () =>
      import('@/views/station-monthly-statement-confirmation/index.vue'),
    meta: {
      title: '确认函',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/fitting-sales-order-letter/:salesOrderNo',
    name: 'FittingSalesOrderLetterRoute',
    component: () =>
      import(
        '@/views/fitting-sales-order/fitting-sales-order-router-letter.vue'
      ),
    meta: {
      title: '销售订单确认函',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/fitting-purchase-order-letter/:purchaseOrderNo',
    name: 'FittingPurchaseOrderLetterRoute',
    component: () =>
      import(
        '@/views/fitting-purchase-order/fitting-purchase-order-router-letter.vue'
      ),
    meta: {
      title: '采购订单确认函',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/vehicle-inspection-report-browse/:vehicleInspectionReportId',
    name: 'VehicleInspectionReportBrowseRoute',
    component: () =>
      import(
        '@/views/vehicle-inspection-report/vehicle-inspection-report-router-browse.vue'
      ),
    meta: {
      title: '星威检车报告',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/vehicle-inspection-report-letter/:vehicleInspectionReportId',
    name: 'VehicleInspectionReportLetterRoute',
    component: () =>
      import(
        '@/views/vehicle-inspection-report/vehicle-inspection-report-router-letter.vue'
      ),
    meta: {
      title: '星威检车报告', // 打印页
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/vehicle-team-monthly-inspection-report-browse/:vehicleTeamMonthlyInspectionReportId',
    name: 'VehicleTeamMonthlyInspectionReportBrowseRoute',
    component: () =>
      import(
        '@/views/vehicle-team-monthly-inspection-report/vehicle-team-monthly-inspection-report-router-browse.vue'
      ),
    meta: {
      title: '星威检车报告', // 车队月度检车报告
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/vehicle-team-monthly-inspection-report-letter/:vehicleTeamMonthlyInspectionReportId',
    name: 'VehicleTeamMonthlyInspectionReportLetterRoute',
    component: () =>
      import(
        '@/views/vehicle-team-monthly-inspection-report/vehicle-team-monthly-inspection-report-router-letter.vue'
      ),
    meta: {
      title: '星威检车报告', // 车队月度检车报告打印页
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/maint-uncovered-quotation-browser/:maintUncoveredQuotationRecordId',
    name: 'MaintUncoveredQuotationBrowserRoute',
    component: () =>
      import(
        '@/views/vehicleMaintenance/details/components/maint-uncovered/maint-uncovered-quotation-router-browser.vue'
      ),
    meta: {
      title: '保外报价单',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/maint-uncovered-quotation-letter/:maintUncoveredQuotationRecordId',
    name: 'MaintUncoveredQuotationLetterRoute',
    component: () =>
      import(
        '@/views/vehicleMaintenance/details/components/maint-uncovered/maint-uncovered-quotation-router-letter.vue'
      ),
    meta: {
      title: '保外报价单', // 保外报价单打印页
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/maint-settled-voucher-browser/:maintOrderNo',
    name: 'MaintSettledVoucherBrowser',
    component: () =>
      import(
        '@/views/vehicleMaintenance/components/maint-settled-voucher-browser.vue'
      ),
    meta: {
      title: '结算单',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/maint-settled-voucher-letter/:maintOrderNo',
    name: 'MaintSettledVoucherLetter',
    component: () =>
      import(
        '@/views/vehicleMaintenance/components/maint-settled-voucher-letter.vue'
      ),
    meta: {
      title: '结算单', // 结算单打印页
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
  },
  {
    path: '/',
    name: 'LayoutBasicRouter',
    redirect: '/home',
    component: LayoutBasicRouter,
    meta: {
      title: '工作台',
      icon: '',
      isMenuRoute: false,
      permissionKeyWithRoute: null,
      permissionKeysWithAction: [],
    },
    children: [
      {
        path: 'home',
        name: 'HomeRouter',
        component: () => import('@/views/home/home-router.vue'),
        meta: {
          title: '首页',
          icon: '',
          isMenuRoute: false,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [],
      },

      {
        path: 'maint',
        name: 'LayoutBlankRouterMaint',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.maint'),
          icon: 'permission-maint.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 车辆维保
           */
          {
            path: 'maint-order',
            name: 'VehicleMaintenanceDetails',
            component: () =>
              import('@/views/vehicleMaintenance/maint-router.vue'),
            meta: {
              title: i18n.global.t('maint.details.vehicleMaint'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'MAINT_MENUS',
                label: i18n.global.t('maint.details.vehicleMaint'),
                agencyTypes: [
                  AgencyType.PLATFORM,
                  AgencyType.STATION,
                  AgencyType.VEHICLE_TEAM,
                ],
              },
              permissionKeysWithAction: [
                {
                  key: 'MAINT_REQUEST',
                  label: `${i18n.global.t('app.common.add')}/${i18n.global.t(
                    'app.common.edit',
                  )}/${i18n.global.t('app.common.cancel')}`,
                  agencyTypes: [AgencyType.PLATFORM, AgencyType.VEHICLE_TEAM],
                },
                {
                  key: 'MAINT_ORDER_TRANSFER',
                  label: i18n.global.t('app.common.maintOrderTransfer'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'MAINT_ORDER_DISPATCH',
                  label: `${i18n.global.t(
                    'app.common.dispatch',
                  )}/${i18n.global.t('app.common.additionalDispatch')}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_STATION_CHANGE,
                  label: i18n.global.t('app.common.maintOrderStationChange'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'MAINT_UNCOVERED_COST_INFORM_SUBMIT',
                  label: i18n.global.t(
                    'app.common.maintUncoveredCostInformSubmit',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'MAINT_ORDER_RECORD_DELEGATE',
                  label: i18n.global.t('app.common.maintOrderRecordDelegate'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_QUOTE_AUDIT,
                  label: i18n.global.t('app.common.maintOrderQuoteAudit'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'MAINT_ORDER_REVOKE',
                  label: i18n.global.t('app.common.maintOrderRevoke'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'MAINT_ORDER_VIEW_ALL',
                  label: i18n.global.t('app.common.maintOrderViewAll'),
                  agencyTypes: [
                    AgencyType.PLATFORM,
                    AgencyType.STATION,
                    AgencyType.VEHICLE_TEAM,
                  ],
                },
                {
                  key: 'MAINT_ORDER_VIEW_APPROVED_USER',
                  label: i18n.global.t('app.common.maintOrderViewApprovedUser'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_REPLACE_AUDITOR,
                  label: i18n.global.t('app.common.maintOrderReplaceAuditor'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'MAINT_REQUEST_IMPORT',
                  label: i18n.global.t('app.common.maintRequestImport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_REMARK_CREATE,
                  label: i18n.global.t('app.common.maintOrderRemarkCreate'),
                  agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
                },
                {
                  key: PermissionKey.MAINT_ORDER_LOCK_UNCLOCK,
                  label: `${i18n.global.t(
                    'user.manage.details.locked',
                  )}/${i18n.global.t('user.manage.details.unlock')}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_RECHECK,
                  label: i18n.global.t('app.common.maintOrderRecheck'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'MAINT_ORDER_RECEIVE',
                  label: i18n.global.t('app.common.maintOrderReceive'),
                  agencyTypes: [AgencyType.STATION],
                },
                {
                  key: 'MAINT_ORDER_ASSIGN',
                  label: i18n.global.t('app.common.maintOrderAssign'),
                  agencyTypes: [AgencyType.STATION],
                },
                {
                  key: 'MAINT_ORDER_CHECKIN',
                  label: i18n.global.t('app.common.maintOrderCheckin'),
                  agencyTypes: [AgencyType.STATION],
                },
                {
                  key: 'MAINT_ORDER_RECORD',
                  label: i18n.global.t('app.common.maintOrderRecord'),
                  agencyTypes: [AgencyType.STATION],
                },
                {
                  key: 'MAINT_ORDER_QUOTE',
                  label: i18n.global.t('app.common.maintOrderQuote'),
                  agencyTypes: [AgencyType.STATION],
                },
                {
                  key: 'MAINT_UNCOVERED_COST_INFORM_AUDIT',
                  label: i18n.global.t(
                    'app.common.maintUncoveredCostInformAudit',
                  ),
                  agencyTypes: [AgencyType.VEHICLE_TEAM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_MAINT_UNCOVERED_QUOTATION_AMOUNT_VIEW,
                  label: '查看维保单费用明细',
                  agencyTypes: [AgencyType.VEHICLE_TEAM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_MAINT_UNCOVERED_QUOTATION_TOTAL_AMOUNT_VIEW,
                  label: '查看维保单费用合计',
                  agencyTypes: [AgencyType.VEHICLE_TEAM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_PAYMENT_SUMMARY_DETAIL_QUERY,
                  label: '查看付款明细',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_PAYMENT_SUMMARY_CORPORATION_TRANSFER,
                  label: '申请公对公付款',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PETTY_CASH_ACCOUNT_PAYMENT,
                  label: '备用金账户付款',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_PAYMENT_SUMMARY_SUBMIT_AUDIT,
                  label: '提交审核',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_PAYMENT_SUMMARY_AUDIT,
                  label: '付款审核',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_PAYMENT_SUMMARY_CORPORATION_VERIFY,
                  label: '公对公付款核销',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },

          /**
           * 车辆里程
           */
          {
            path: 'vehicle-mileage',
            name: 'VehicleMileageList',
            component: () => import('@/views/vehicleMileage/list'),
            meta: {
              title: i18n.global.t('app.common.vehicleMileage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'VEHICLE_MILEAGE',
                label: i18n.global.t('app.common.vehicleMileage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'VEHICLE_MILEAGE_EXPORT',
                  label: i18n.global.t('app.common.vehicleMileageExport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'VEHICLE_MILEAGE_MONTHLY_CONFIRM',
                  label: i18n.global.t(
                    'app.common.vehicleMileageMonthlyConfirm',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'VEHICLE_MILEAGE_CALIBRATION',
                  label: i18n.global.t('app.common.vehicleMileageCalibration'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 里程对账
           */
          {
            path: 'vehicle-mileage-monthly-statement',
            name: 'List',
            component: () => import('@/views/mileageReconciliation/list'),
            meta: {
              title: i18n.global.t('app.common.vehicleMileageStatement'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'VEHICLE_MILEAGE_STATEMENT',
                label: i18n.global.t('app.common.vehicleMileageStatement'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'VEHICLE_MILEAGE_MONTHLY_RECONCILE',
                  label: i18n.global.t(
                    'app.common.vehicleMileageMonthlyReconcile',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 维保计划
           */
          {
            path: 'maint-plan',
            name: 'MaintenancePlanList',
            component: () => import('@/views/maintenancePlan/list'),
            meta: {
              title: i18n.global.t('app.common.maintPlan'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'MAINT_PLAN',
                label: i18n.global.t('app.common.maintPlan'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'MAINT_PLAN_EXPORT',
                  label: `${i18n.global.t(
                    'app.common.maintPlan',
                  )}${i18n.global.t('app.common.export')}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
        ],
      },

      {
        path: 'sale',
        name: 'LayoutBlankRouterSale',
        component: LayoutBlankRouter,
        meta: {
          title: '采销',
          icon: 'permission-finance.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 服务站配件商城
           */
          {
            path: 'fitting-sales-orders-blank',
            name: 'FittingSalesOrderRouterBlank',
            component: () =>
              import(
                '@/views/fitting-sales-order/fitting-sales-order-router-blank.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.fittingShoppingMall'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.STATION_FITTING_SHOPPING_MALL,
                label: i18n.global.t('app.common.stationFittingShoppingMall'),
                agencyTypes: [AgencyType.STATION],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 销售订单
           */
          {
            path: 'fitting-sales-orders',
            name: 'FittingSalesOrderRoute',
            component: () =>
              import(
                '@/views/fitting-sales-order/fitting-sales-order-router.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.menuSalesOrders'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.SALES_ORDER_MENU,
                label: i18n.global.t('app.common.menuSalesOrders'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.SALES_ORDER_CREATE_EDIT,
                  label: '新增/修改/批量新增销售订单',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.SALES_ORDER_EXPORT,
                  label: '销售订单导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.SALES_ORDER_COLLECTION_REGISTRATION,
                  label: '线下收款登记',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.SALES_ORDER_DELIVER,
                  label: '销售订单发货',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.SALES_ORDER_REVOKE,
                  label: '作废订单',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 销售订单详情
           */
          {
            path: 'fitting-sales-order-browse/:salesOrderNo',
            name: 'FittingSalesOrderBrowseRoute',
            component: () =>
              import(
                '@/views/fitting-sales-order/fitting-sales-order-router-browse.vue'
              ),
            meta: {
              title: '销售订单详情',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: PermissionKey.SALES_ORDER_MENU,
                label: '销售订单详情',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          /**
           * 采购订单
           */
          {
            path: 'fitting-purchase-order',
            name: 'FittingPurchaseOrderRoute',
            component: () =>
              import(
                '@/views/fitting-purchase-order/fitting-purchase-order-router.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.menuPurchaseOrders'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.PURCHASE_ORDER_MENU,
                label: i18n.global.t('app.common.menuPurchaseOrders'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.PURCHASE_ORDER_CREATE,
                  label: '新增采购订单',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PURCHASE_ORDER_CONFIRM_RECEIPT,
                  label: '确认收货',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PURCHASE_ORDER_REVOKE,
                  label: '作废订单',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PURCHASE_ORDER_PAYMENT_REGISTER,
                  label: '完成付款',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PURCHASE_ORDER_EXPORT,
                  label: '采购单导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 销售订单详情
           */
          {
            path: 'fitting-purchase-order-browse/:purchaseOrderNo',
            name: 'FittingPurchaseOrderBrowseRoute',
            component: () =>
              import(
                '@/views/fitting-purchase-order/fitting-purchase-order-router-browse.vue'
              ),
            meta: {
              title: '采购订单详情',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: PermissionKey.PURCHASE_ORDER_MENU,
                label: '采购订单',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          /**
           * 供应商管理
           */
          {
            path: 'fitting-supplier',
            name: 'FittingSupplierRoute',
            component: () =>
              import('@/views/fitting-supplier/fitting-supplier-router.vue'),
            meta: {
              title: i18n.global.t('app.common.menuVendor'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.FITTING_SUPPLIER_MENU,
                label: i18n.global.t('app.common.menuVendor'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.FITTING_SUPPLIER_ADD,
                  label: '新增',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.FITTING_SUPPLIER_EDIT,
                  label: '编辑',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.FITTING_SUPPLIER_IMPORT,
                  label: '导入',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.FITTING_SUPPLIER_EXPORT,
                  label: '导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },

          /**
           * 商品管理
           */
          {
            path: 'fitting-goods',
            name: 'FittingGoodsRoute',
            component: () =>
              import('@/views/fitting-goods/fitting-goods-router.vue'),
            meta: {
              title: i18n.global.t('app.common.menuProduct'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.GOODS_MENU,
                label: i18n.global.t('app.common.menuProduct'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.GOODS_UPSERT,
                  label: '新增/编辑商品',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.GOODS_IMPORT,
                  label: '导入商品',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.GOODS_EXPORT,
                  label: '导出商品',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.GOODS_STATUS_CHANGE,
                  label: '上架下架商品',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },

          /**
           * 服务站直供件库存监控
           */
          {
            path: 'fitting-inventory-watcher',
            name: 'FittingInventoryWatcherRoute',
            component: () =>
              import(
                '@/views/fitting-inventory-monitor/fitting-inventory-monitor-router.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.menuInventory'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.STATION_INVENTORY_MONITOR_MENU,
                label: i18n.global.t('app.common.menuInventory'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.STATION_INVENTORY_MONITOR_THRESHOLD_IMPORT,
                  label: '导入',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_INVENTORY_MONITOR_THRESHOLD_EXPORT,
                  label: '导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_INVENTORY_MONITOR_INVENTORY_CHANGE,
                  label: '出库入库',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },

          /**
           * 旧件返还
           */
          {
            path: 'fitting-recycle',
            name: 'FittingRecycle',
            component: () => import('@/views/fitting-recycle/index.vue'),
            meta: {
              title: '旧件返还',
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.MAINT_FITTING_RECYCLE_MENU,
                label: '旧件返还',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.MAINT_FITTING_RECYCLE_CONFIRM_DELIVERED,
                  label: '确认收货',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_FITTING_RECYCLE_NOT_RECYCLE,
                  label: '确认无法返还',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_FITTING_RECYCLE_ENTERED_INVENTORY,
                  label: '入库',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_FITTING_RECYCLE_DESTROYED,
                  label: '销毁',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
        ],
      },

      {
        path: 'vehicle-group',
        name: 'LayoutBlankRouterVehicleTeam',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.vehicleTeam'),
          icon: 'permission-vehicle-team.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 车组管理
           */
          {
            path: '',
            name: 'VehicleGroupRouter',
            component: () => import('@/views/vehicleTeam/user/details.vue'),
            meta: {
              title: i18n.global.t('app.common.vehicleGroupManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'VEHICLE_GROUP_MANAGE',
                label: i18n.global.t('app.common.vehicleGroupManage'),
                agencyTypes: [AgencyType.VEHICLE_TEAM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 车辆管理
           */
          {
            path: 'vehicle',
            name: 'VehicleDetails',
            component: () => import('@/views/vehicle/details'),
            meta: {
              title: i18n.global.t('app.common.vehicleManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'VEHICLE_MANAGE',
                label: i18n.global.t('app.common.vehicleManage'),
                agencyTypes: [AgencyType.VEHICLE_TEAM],
              },
            },
          },
        ],
      },
      {
        path: 'finance',
        name: 'LayoutBlankRouterFinance',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.finance'),
          icon: 'permission-finance.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 费用账单
           */
          {
            path: 'billing',
            name: 'Billing',
            component: () => import('@/views/billing/billing.vue'),
            meta: {
              title: i18n.global.t('app.common.billing'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.BILLING_VIEW,
                label: i18n.global.t('app.common.billing'),
                agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 扣款明细
           */
          {
            path: 'station-amercement',
            name: 'StationAmercement',
            component: () =>
              import('@/views/station-amercement/station-amercement.vue'),
            meta: {
              title: i18n.global.t('app.common.stationAmercementDetail'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.STATION_AMERCEMENT_DETAIL_BILLING,
                label: i18n.global.t('app.common.stationAmercementDetail'),
                agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 对账单
           */
          {
            path: 'station-monthly-statement',
            name: 'StationMonthlyStatementRouter',
            component: () =>
              import(
                '@/views/station-monthly-statement/station-monthly-statement-router.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.stationMonthlyStatement'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.STATION_MONTHLY_STATEMENT_MENU,
                label: i18n.global.t('app.common.stationMonthlyStatement'),
                agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_PLATFORM_CONFIRM,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementPlatformConfirm',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_REGENERATE,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementRegenerate',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_SETTLE,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementSettle',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_PLATFORM_EXPORT,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementPlatformExport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_FEE_IMPORT,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementFeeImport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_COMMIT_INVOICE,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementCommitInvoice',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_CONFIRM_DOWNLOAD,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementConfirmDownload',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_STATION_CONFIRM,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementPlatformConfirm',
                  ),
                  agencyTypes: [AgencyType.STATION],
                },
                {
                  key: PermissionKey.STATION_MONTHLY_STATEMENT_STATION_EXPORT,
                  label: i18n.global.t(
                    'app.common.stationMonthlyStatementPlatformExport',
                  ),
                  agencyTypes: [AgencyType.STATION],
                },
              ],
            },
            children: [],
          },
          /**
           * 结算账单
           */
          {
            path: 'maint-settlement-order',
            name: 'MaintSettlementOrderRouter',
            component: () =>
              import(
                '@/views/maint-settlement-order/maint-settlement-order-router-platform.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.maintSettlementOrder'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.MAINT_SETTLEMENT_ORDER,
                label: i18n.global.t('app.common.maintSettlementOrder'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.IMPORT_MAINT_SETTLEMENT_INFO,
                  label: i18n.global.t('app.common.importMaintSettlementInfo'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.EXPORT_MAINT_SETTLEMENT_ORDER,
                  label: i18n.global.t('app.common.exportMaintSettlementOrder'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 外部账单
           */
          {
            path: 'external-billing',
            name: 'ExternalBilling',
            component: () =>
              import('@/views/external-billing/external-billing.vue'),
            meta: {
              title: i18n.global.t('app.common.externalBilling'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.EXTERNAL_STATEMENT_ORDER_MENU,
                label: i18n.global.t('app.common.externalBilling'),
                agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.EXTERNAL_STATEMENT_ORDER_PLATFORM_IMPORT,
                  label: i18n.global.t(
                    'app.common.importPlatformExternalBilling',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.EXTERNAL_STATEMENT_ORDER_PLATFORM_EXPORT,
                  label: i18n.global.t(
                    'app.common.exportPlatformExternalBillingOrder',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.EXTERNAL_STATEMENT_ORDER_STATION_EXPORT,
                  label: i18n.global.t(
                    'app.common.exportStationExternalBillingOrder',
                  ),
                  agencyTypes: [AgencyType.STATION],
                },
              ],
            },
            children: [],
          },
          /**
           * 保外账单
           */
          {
            path: 'maint-uncovered-billing',
            name: 'MaintUncoveredBilling',
            component: () =>
              import(
                '@/views/maint-uncovered-billing/maint-uncovered-billing-router.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.maintUncoveredBilling'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.MAINT_PRE_QUOTATION_SETTLEMENT_MENU,
                label: i18n.global.t('app.common.maintUncoveredBilling'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.MAINT_PRE_QUOTATION_SETTLEMENT_IMPORT_STATEMENT,
                  label: i18n.global.t(
                    'app.common.maintUncoveredBillingImportReconciliation',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_PRE_QUOTATION_SETTLEMENT_IMPORT_SETTLED,
                  label: i18n.global.t(
                    'app.common.maintUncoveredBillingImportSettlement',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_PRE_QUOTATION_SETTLEMENT_EXPORT,
                  label: i18n.global.t(
                    'app.common.maintUncoveredBillingExportDetail',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 保外对账
           */
          {
            path: 'maint-uncovered-vehicle-team-reconciliation',
            name: 'MaintUncoveredVehicleTeamReconciliation',
            component: () =>
              import(
                '@/views/maint-uncovered-vehicle-team-reconciliation/maint-uncovered-vehicle-team-reconciliation-router.vue'
              ),
            meta: {
              title: '保外对账',
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.MAINT_PRE_QUOTATION_SETTLEMENT_RECONCILIATION,
                label: '保外对账',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 费用登记
           */
          {
            path: 'resident-fee',
            name: 'ResidentFeeRoute',
            component: () =>
              import('@/views/resident-fee/resident-fee-router.vue'),
            meta: {
              title: '费用登记',
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.RESIDENT_FEE_MENU,
                label: '费用登记',
                agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.RESIDENT_FEE_ADD,
                  label: '费用登记（新增）',
                  agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
                },
                {
                  key: PermissionKey.RESIDENT_RETURN_GOODS_MONEY,
                  label: '退钱退货',
                  agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
                },
                {
                  key: PermissionKey.RESIDENT_RETURN_MONEY,
                  label: '退钱不退货',
                  agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
                },
                {
                  key: PermissionKey.RESIDENT_FEE_EXPORT,
                  label: '明细导出',
                  agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
                },
              ],
            },
            children: [],
          },
        ],
      },

      /**
       * 资产
       */
      {
        path: 'assets',
        name: 'LayoutBlankRouterAssets',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.property'),
          icon: 'permission-property.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 车辆管理
           */
          {
            path: 'vehicle',
            name: 'VehicleDetails',
            component: () => import('@/views/vehicle/details'),
            meta: {
              title: i18n.global.t('app.common.vehicleManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'VEHICLE_MANAGE',
                label: i18n.global.t('app.common.vehicleManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'VEHICLE_IMPORT',
                  label: i18n.global.t('app.common.vehicleImport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'VEHICLE_SERVICE_INFO_IMPORT',
                  label: i18n.global.t('app.common.vehicleServiceInfoImport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'VEHICLE_DRIVER_IMPORT',
                  label: i18n.global.t('app.common.vehicleDriverImport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_DRIVER_MAINTAIN,
                  label: i18n.global.t('app.common.vehicleDriverMaintain'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_LICENSE_EDIT,
                  label: i18n.global.t('app.common.vehicleLicenseEdit'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'VEHICLE_DETAIL_IMPORT',
                  label: i18n.global.t('app.common.vehicleDetailImport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_DETAIL_EDIT,
                  label: `${i18n.global.t('app.common.edit')}${i18n.global.t(
                    'app.common.details',
                  )}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_EFFECTIVE_AND_INEFFECTIVE,
                  label: i18n.global.t(
                    'app.common.vehicleEffectiveAndIneffective',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_GROUP_IMPORT,
                  label: i18n.global.t('app.common.vehicleTeamGroupImport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_OWNERSHIP_CHANGE,
                  label: i18n.global.t('app.common.fleetOwnershipEdit'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_LIC_IMG_IMPORT,
                  label: i18n.global.t('app.common.vehicleLicImgImport'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_LICENSE_ADD,
                  label: i18n.global.t('app.common.vehicleLicenseAdd'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_MAINT_COVER_HISTORY_EDIT,
                  label: i18n.global.t(
                    'app.common.vehicleMaintCoverHistoryEdit',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
          },

          {
            path: 'vehicle/new',
            name: 'AddVehicle',
            component: () => import('@/views/vehicle/add'),
            meta: {
              title: '新增车辆',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'VEHICLE_MANAGE',
                label: '新增车辆',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 车队管理
           */
          {
            path: 'vehicle-team',
            name: 'VehicleTeamDetails',
            component: () => import('@/views/vehicleTeam/details'),
            meta: {
              title: i18n.global.t('app.common.vehicleTeamManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'VEHICLE_TEAM_MANAGE',
                label: i18n.global.t('app.common.vehicleTeamManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'VEHICLE_TEAM_OPERATE',
                  label: i18n.global.t('app.common.vehicleTeamOperate'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_GROUP_ASSOCIATED,
                  label: i18n.global.t('app.common.vehicleTeamGroupAssociated'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_MAINT_UNCOVERED_PIP_OPERATE,
                  label: `${i18n.global.t('app.common.edit')}${i18n.global.t(
                    'app.common.vehicleTeamMaintUncoveredPipOperate',
                  )}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_GROUP_EDIT,
                  label: `${i18n.global.t('app.common.edit')}${i18n.global.t(
                    'app.common.vehicleTeamGroupEdit',
                  )}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_GROUP_STATION_MAINT_UNCOVERED_PIP_IMPORT,
                  label: i18n.global.t(
                    'app.common.vehicleGroupStationMaintUncoveredPipImport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_GROUP_STATION_MAINT_UNCOVERED_PIP_EXPORT,
                  label: i18n.global.t(
                    'app.common.vehicleGroupStationMaintUncoveredPipExport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_MAINT_UNCOVERED_OFFER_PRICE_IMPORT,
                  label: i18n.global.t(
                    'app.common.vehicleTeamMaintUncoveredOfferPriceImport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.VEHICLE_TEAM_MAINT_UNCOVERED_OFFER_PRICE_EXPORT,
                  label: i18n.global.t(
                    'app.common.vehicleTeamMaintUncoveredOfferPriceExport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 服务站管理
           */

          {
            path: 'station/new',
            name: 'NewStation',
            component: () =>
              import('@/views/station/new-station/new-station.vue'),
            meta: {
              title: '新增服务站',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'SERVICE_STATION_MANAGE',
                label: '新增服务站',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'station/main-contract/new',
            name: 'NewMainContract',
            component: () =>
              import('@/views/station/new-main-contract/new-main-contract.vue'),
            meta: {
              title: '签约主合同',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'SERVICE_STATION_MANAGE',
                label: '签约主合同',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'station/fitting-direct-supply-contract/new',
            name: 'NewFittingDirectSupplyContract',
            component: () =>
              import(
                '@/views/station/new-fitting-direct-supply-contract/new-fitting-direct-supply-contract.vue'
              ),
            meta: {
              title: '签约直供件合同',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'SERVICE_STATION_MANAGE',
                label: '签约直供件合同',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'station/setting',
            name: 'SettingStation',
            component: () =>
              import('@/views/station/setting-station/setting-station.vue'),
            meta: {
              title: '编辑服务站信息',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'SERVICE_STATION_MANAGE',
                label: '编辑服务站信息',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'station/contracted-station/setting',
            name: 'SettingContractStation',
            component: () =>
              import(
                '@/views/station/setting-contracted-station/setting-contracted-station.vue'
              ),
            meta: {
              title: '编辑签约站信息',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'SERVICE_STATION_MANAGE',
                label: '编辑签约站信息',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'station',
            name: 'StationDetail',
            component: () => import('@/views/station'),
            meta: {
              title: i18n.global.t('app.common.serviceStationManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'SERVICE_STATION_MANAGE',
                label: i18n.global.t('app.common.serviceStationManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'SERVICE_STATION_VIEW_ALL',
                  label: i18n.global.t('app.common.serviceStationViewAll'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'STATION_DSFITTING_INVENTORY_OPERATE',
                  label: i18n.global.t(
                    'app.common.stationDsfittingInventoryOperate',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_AMERCEMENT_VIEW,
                  label: i18n.global.t('app.common.stationAmercementView'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_AMERCEMENT_UPSERT,
                  label: `${i18n.global.t('app.common.add')}/${i18n.global.t(
                    'app.common.edit',
                  )}${i18n.global.t('app.common.stationAmercement')}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_AMERCEMENT_BATCH_CREATE,
                  label: `${i18n.global.t(
                    'app.common.stationAmercementBatchCreate',
                  )}`,
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'SERVICE_STATION_OPERATE',
                  label: i18n.global.t('app.common.serviceStationOperate'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_CONFIRM_RECEIVED_MARGIN_PAYMENT,
                  label: i18n.global.t(
                    'app.common.stationConfirmReceivedMarginPayment',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_SIGN_CONTRACT_CONFIRM,
                  label: i18n.global.t('app.common.stationSignContractConfirm'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_SUSPEND_RESUME_DISPATCH,
                  label: i18n.global.t(
                    'app.common.stationSuspendResumeDispatch',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_WORKINGHOUR_PRICE_OPERATE,
                  label: i18n.global.t(
                    'app.common.stationWorkinghourPriceOperate',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_FITTING_PRICE_OPERATE,
                  label: i18n.global.t('app.common.stationFittingPriceOperate'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_BASE_INFO_IMPORT,
                  label: '服务站基础信息导入',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 分拨点管理
           */
          {
            path: 'distribution-center',
            name: 'DistributionCenterDetails',
            component: () =>
              import('@/views/distribution-center/details/index.vue'),
            meta: {
              title: i18n.global.t('app.common.distributionCenterManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'DISTRIBUTION_CENTER_MANAGE',
                label: i18n.global.t('app.common.distributionCenterManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'DISTRIBUTION_CENTER_OPERATE',
                  label: i18n.global.t('app.common.distributionCenterOperate'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.DISTRIBUTION_CENTER_IMPORT,
                  label: '分拨点导入',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.DISTRIBUTION_CENTER_EXPORT,
                  label: '分拨点导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },

          {
            path: 'distribution-center/new',
            name: 'AddDistributionCenter',
            component: () => import('@/views/distribution-center/add'),
            meta: {
              title: '新增分拨点',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'DISTRIBUTION_CENTER_OPERATE',
                label: '新增分拨点',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'distribution-center/setting/:distributionCenterId',
            name: 'EditDistributionCenter',
            component: () => import('@/views/distribution-center/add'),
            meta: {
              title: '编辑分拨点',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'DISTRIBUTION_CENTER_OPERATE',
                label: '编辑分拨点',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 直供件管理
           */
          {
            path: 'fitting-direct-supply',
            name: 'DirectSupplyManageList',
            component: () => import('@/views/directSupplyManage/list/index'),
            meta: {
              title: i18n.global.t('app.common.directSupplyFittingView'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'DIRECT_SUPPLY_FITTING_VIEW',
                label: i18n.global.t('app.common.directSupplyFittingView'),
                agencyTypes: [AgencyType.STATION],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.DIRECT_SUPPLY_FITTING_ADJUST,
                  label: '直供件库存调整',
                  agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
                },
              ],
            },
            children: [],
          },
        ],
      },

      {
        path: 'database',
        name: 'LayoutBlankRouterDatabase',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.data'),
          icon: 'permission-data.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 工时库
           */
          {
            path: 'workinghour',
            name: 'WorkinghourRouter',
            component: () => import('@/views/workinghour/details'),
            meta: {
              title: i18n.global.t('app.common.workinghourManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'WORKINGHOUR_MANAGE',
                label: i18n.global.t('app.common.workinghourManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.WORKINGHOUR_MANAGE_EDIT,
                  label: i18n.global.t('app.common.workinghourManageEdit'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 配件库
           */
          {
            path: 'fitting',
            name: 'FittingRouter',
            component: () => import('@/views/fittingManage/details'),
            meta: {
              title: i18n.global.t('app.common.fittingManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'FITTING_MANAGE',
                label: i18n.global.t('app.common.fittingManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.FITTING_MANAGE_EDIT,
                  label: i18n.global.t('app.common.fittingManageEdit'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 型号库
           */
          {
            path: 'fitting-model',
            name: 'ModelManageDetails',
            component: () => import('@/views/modelManage/details'),
            meta: {
              title: i18n.global.t('app.common.fittingModelManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'FITTING_MODEL_MANAGE',
                label: i18n.global.t('app.common.fittingModelManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.FITTING_MODEL_MANAGE_EDIT,
                  label: i18n.global.t('app.common.fittingModelManageEdit'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 故障库
           */
          {
            path: 'fault',
            name: 'FaultRouter',
            component: () => import('@/views/faultManage/details'),
            meta: {
              title: i18n.global.t('app.common.faultManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'FAULT_MANAGE',
                label: i18n.global.t('app.common.faultManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.FAULT_MANAGE_EDIT,
                  label: i18n.global.t('app.common.faultManageEdit'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },

          {
            path: 'fault/new',
            name: 'FaultNewRouter',
            component: () => import('@/views/faultManage/add'),
            meta: {
              title: '新增故障',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'FAULT_MANAGE',
                label: '新增故障',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'fault/setting',
            name: 'FaultSettingRouter',
            component: () => import('@/views/faultManage/add'),
            meta: {
              title: '编辑故障',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'FAULT_MANAGE',
                label: '编辑故障',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
        ],
      },

      {
        path: 'system',
        name: 'LayoutBlankRouterSystem',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.system'),
          icon: 'permission-system.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 账号管理
           */
          {
            path: 'user',
            name: 'UserRouter',
            component: () => import('@/views/user/user-router.vue'),
            meta: {
              title: i18n.global.t('app.common.userManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'USER_MANAGE',
                label: i18n.global.t('app.common.userManage'),
                agencyTypes: [
                  AgencyType.PLATFORM,
                  AgencyType.STATION,
                  AgencyType.VEHICLE_TEAM,
                ],
              },
              permissionKeysWithAction: [
                {
                  key: 'MAINT_ORDER_AUDIT_CONFIG',
                  label: i18n.global.t('app.common.maintOrderAuditConfig'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'USER_SPECIAL_DUTY_ROSTER_IMPORT',
                  label: i18n.global.t(
                    'app.common.userSpecialDutyRosterImport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: 'USER_SPECIAL_DUTY_ROSTER_EXPORT',
                  label: i18n.global.t(
                    'app.common.userSpecialDutyRosterExport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PETTY_CASH_ACCOUNT_STATUS_SETTING,
                  label: '开启关闭备用金账号',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PETTY_CASH_CREDIT_APPLY,
                  label: '申请备用金临时额度',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.PETTY_CASH_CREDIT_APPROVED,
                  label: '审批备用金临时额度',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
          },
          /**
           * 角色管理
           */

          {
            path: 'permission/new',
            name: 'PermissionAdd',
            component: () => import('@/views/permissionManage/add'),
            meta: {
              title: '新增角色',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'PERMISSION_MANAGE',
                label: '新增角色',
                agencyTypes: [
                  AgencyType.PLATFORM,
                  AgencyType.STATION,
                  AgencyType.VEHICLE_TEAM,
                ],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'permission/setting',
            name: 'PermissionEdit',
            component: () => import('@/views/permissionManage/add'),
            meta: {
              title: '编辑角色',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: {
                key: 'PERMISSION_MANAGE',
                label: '编辑角色',
                agencyTypes: [
                  AgencyType.PLATFORM,
                  AgencyType.STATION,
                  AgencyType.VEHICLE_TEAM,
                ],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          {
            path: 'permission',
            name: 'PermissionRouter',
            component: () => import('@/views/permissionManage/details'),
            meta: {
              title: i18n.global.t('app.common.permissionManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'PERMISSION_MANAGE',
                label: i18n.global.t('app.common.permissionManage'),
                agencyTypes: [
                  AgencyType.PLATFORM,
                  AgencyType.STATION,
                  AgencyType.VEHICLE_TEAM,
                ],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 对接管理
           */
          {
            path: 'secret-key',
            name: 'SecretKeyRouter',
            component: () => import('@/views/vehicleTeam/secretKey/index.vue'),
            meta: {
              title: '对接管理',
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.INTEGRATION_MANAGEMENT_MENU,
                label: '对接管理',
                agencyTypes: [AgencyType.VEHICLE_TEAM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },

          /**
           * 标签管理
           */
          {
            path: 'phrase',
            name: 'PhraseManageDetails',
            component: () => import('@/views/phraseManage/details'),
            meta: {
              title: i18n.global.t('app.common.phraseManage'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'PHRASE_MANAGE',
                label: i18n.global.t('app.common.phraseManage'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 系统设置
           */
          {
            path: 'settings',
            name: 'SystemSetting',
            component: () => import('@/views/system-setting'),
            meta: {
              title: i18n.global.t('app.common.systemSetting'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.SYSTEM_SETTING,
                label: i18n.global.t('app.common.systemSetting'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
          /**
           * 风控日志
           */
          {
            path: 'risk-control-log',
            name: 'RiskControlLog',
            component: () => import('@/views/riskControlLog/details'),
            meta: {
              title: i18n.global.t('app.common.riskControlLog'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.RISK_CONTROL_LOG,
                label: i18n.global.t('app.common.riskControlLog'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
        ],
      },

      {
        path: 'utils',
        name: 'LayoutBlankRouterUtils',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.tool'),
          icon: 'permission-tool.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 数据导入
           */
          {
            path: 'data-import',
            name: 'UtilsDataImport',
            component: () => import('@/views/tools/data-import/index.vue'),
            meta: {
              title: i18n.global.t('app.common.businessDataImport'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'BUSINESS_DATA_IMPORT',
                label: i18n.global.t('app.common.businessDataImport'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.MAINT_ORDER_RECHECK_DISPATCHED_IMPORT,
                  label: i18n.global.t(
                    'app.common.maintOrderRecheckDispatchedImport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_RECHECK_DISPATCHED_EXPORT,
                  label: i18n.global.t(
                    'app.common.maintOrderRecheckDispatchedExport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.AN_NENG_MAINT_ORDER_SETTLED_IMPORT,
                  label: '安能已结算维保单更新',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.AN_NENG_FITTING_MODEL_MAPPING_IMPORT,
                  label: i18n.global.t(
                    'app.common.anNengFittingModelMappingImport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.AN_NENG_FITTING_MODEL_MAPPING_EXPORT,
                  label: i18n.global.t(
                    'app.common.anNengFittingModelMappingExport',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 数据导出
           */
          {
            path: 'data-export',
            name: 'UtilsDataExport',
            component: () => import('@/views/tools/data-export/index.vue'),
            meta: {
              title: i18n.global.t('app.common.businessDataExport'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: 'BUSINESS_DATA_EXPORT',
                label: i18n.global.t('app.common.businessDataExport'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: 'VEHICLE_COVER_HISTORY_EXPORT',
                  label: '导出车辆承保记录',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_EXPORT,
                  label: '维保单导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.RESIDENT_STATION_INVENTORY_EXPORT,
                  label: '驻点配件库存导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_PAYMENT_SUMMARY_EXPORT,
                  label: '备用金账户汇总及明细导出',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 实用工具
           */
          {
            path: 'tool',
            name: 'UtilsTool',
            component: () => import('@/views/tools/utils/index.vue'),
            meta: {
              title: i18n.global.t('app.common.utilityTools'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.UTILITY_TOOLS,
                label: i18n.global.t('app.common.utilityTools'),
                agencyTypes: [AgencyType.PLATFORM, AgencyType.STATION],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.STATION_BALANCE_DEDUCTION,
                  label: i18n.global.t('app.common.stationBalanceDeduction'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_BALANCE_DEDUCTION_SNAPSHOT,
                  label: i18n.global.t(
                    'app.common.stationBalanceDeductionSnapshot',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.SHENTONG_SETTLED_VOUCHER_SETTING,
                  label: i18n.global.t(
                    'app.common.shenTongSettledVoucherSetting',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.STATION_BATCH_MAINT_BUTTER,
                  label: i18n.global.t('app.common.stationBatchMaintButter'),
                  agencyTypes: [AgencyType.STATION],
                },
              ],
            },
            children: [],
          },
          /**
           * 抽佣设置
           */
          {
            path: 'station-commission-rule',
            name: 'StationCommissionRuleRouter',
            component: () =>
              import(
                '@/views/station-commission-rule/station-commission-rule-router.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.menuCommission'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.STATION_COMMISSION_RULE_MENU,
                label: i18n.global.t('app.common.menuCommission'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.STATION_COMMISSION_RULE_OPERATE,
                  label: '操作权限',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 生产数据处理
           */
          {
            path: 'production-data-process',
            name: 'ProductionDataProcess',
            component: () =>
              import('@/views/production-data-process/index.vue'),
            meta: {
              title: i18n.global.t('app.common.dataProcess'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.PRODUCTION_DATA_PROCESS,
                label: i18n.global.t('app.common.productionDataProcess'),
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.MAINT_ORDER_REVOKE_AFTER_QUOTE_ACCEPTED,
                  label: i18n.global.t('app.common.revokeAfterQuoteAccepted'),
                  agencyTypes: [AgencyType.PLATFORM],
                },
                {
                  key: PermissionKey.MAINT_ORDER_REVOKE_AFTER_QUOTE_SNAPSHOT,
                  label: i18n.global.t(
                    'app.common.revokeAfterQuoteAcceptedSnapshot',
                  ),
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 系统日志
           */
          {
            path: 'system-log',
            name: 'SystemLog',
            component: () => import('@/views/system-log/system-log-router.vue'),
            meta: {
              title: i18n.global.t('app.common.systemLog'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.SYSTEM_LOG,
                label: i18n.global.t('app.common.systemLog'),
                agencyTypes: [AgencyType.PLATFORM],
              },
            },
          },
        ],
      },

      {
        path: 'report',
        name: 'LayoutBlankRouterReport',
        component: LayoutBlankRouter,
        meta: {
          title: i18n.global.t('app.common.report'),
          icon: 'permission-tool.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 车检报告明细
           */
          {
            path: 'vehicle-inspection-report',
            name: 'VehicleInspectionReportRouter',
            component: () =>
              import(
                '@/views/vehicle-inspection-report/vehicle-inspection-report-router.vue'
              ),
            meta: {
              title: i18n.global.t('app.common.vehicleInspectionReport'),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.VEHICLE_INSPECTION_REPORT_MENU,
                label: i18n.global.t('app.common.vehicleInspectionReport'),
                agencyTypes: [AgencyType.PLATFORM, AgencyType.VEHICLE_TEAM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.VEHICLE_INSPECTION_REPORT_EXPORT,
                  label: '导出明细',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
          /**
           * 月度检车报告
           */
          {
            path: 'vehicle-team-monthly-inspection',
            name: 'VehicleTeamMonthlyInspectionRouter',
            component: () =>
              import(
                '@/views/vehicle-team-monthly-inspection-report/vehicle-team-monthly-inspection-report-router.vue'
              ),
            meta: {
              title: i18n.global.t(
                'app.common.vehicleTeamMonthlyInspectionReport',
              ),
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.VEHICLE_TEAM_MONTHLY_INSPECTION_REPORT_MENU,
                label: i18n.global.t(
                  'app.common.vehicleTeamMonthlyInspectionReport',
                ),
                agencyTypes: [AgencyType.PLATFORM, AgencyType.VEHICLE_TEAM],
              },
              permissionKeysWithAction: [
                {
                  key: PermissionKey.VEHICLE_TEAM_MONTHLY_INSPECTION_REPORT_DOWNLOAD_MULTIPLY,
                  label: '批量下载',
                  agencyTypes: [AgencyType.PLATFORM],
                },
              ],
            },
            children: [],
          },
        ],
      },

      // 报表
      {
        path: 'report-form',
        name: 'LayoutBlankRouterReportForm',
        component: LayoutBlankRouter,
        meta: {
          title: '报表',
          icon: 'permission-report-form.svg',
          isMenuRoute: true,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 账号管理
           */
          {
            path: 'call-logs',
            name: 'CallLogs',
            component: () => import('@/views/call-logs/index'),
            meta: {
              title: '运营报表',
              icon: '',
              isMenuRoute: true,
              permissionKeyWithRoute: {
                key: PermissionKey.OPERATIONS_REPORT_MENU,
                label: '运营报表',
                agencyTypes: [AgencyType.PLATFORM],
              },
              permissionKeysWithAction: [],
            },
            children: [],
          },
        ],
      },
      {
        path: 'account',
        name: 'LayoutBlankRouterAccount',
        component: LayoutBlankRouter,
        meta: {
          title: '账号',
          icon: '',
          isMenuRoute: false,
          permissionKeyWithRoute: null,
          permissionKeysWithAction: [],
        },
        children: [
          /**
           * 账号管理
           */
          {
            path: '',
            name: 'AccountManage',
            component: () => import('@/views/accountManage/index'),
            meta: {
              title: '账号管理',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: null,
              permissionKeysWithAction: [],
            },
            children: [],
          },
          {
            path: 'modify-phone',
            name: 'AccountManageModifyPhone',
            component: () => import('@/views/accountManage/modifyPhone'),
            meta: {
              title: '修改手机号',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: null,
              permissionKeysWithAction: [],
            },
            children: [],
          },
          {
            path: 'modify-password',
            name: 'AccountManageModifyPassword',
            component: () => import('@/views/accountManage/modifyPassword'),
            meta: {
              title: '修改密码',
              icon: '',
              isMenuRoute: false,
              permissionKeyWithRoute: null,
              permissionKeysWithAction: [],
            },
            children: [],
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    hidden: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: allRoutes,
});

export default router;
