export default {
  'vehicle.management': 'Vehicles Management',
  'vehicle.management.vehiclePlateNo': 'Plate No.',
  'vehicle.management.fleetGroup': 'Fleet Group',
  'vehicle.management.coverageType': 'Coverage Type',
  'vehicle.management.coverageType.select':
    'Please select the type of Coverage Type',
  'vehicle.management.vehicleBusinessTypes': 'Business Type',
  'vehicle.management.vehicleBusinessTypes.select':
    'Please select the type of Business Type',
  'vehicle.management.serviceManager': 'Service Manager',
  'vehicle.management.vehicleType': 'Vehicle Type',
  'vehicle.management.vehicleType.select':
    'Please select the type of vehicle type',
  'vehicle.management.covered': 'Covered',
  'vehicle.management.uncovered': 'Uncovered',
  'vehicle.management.Tractor': 'Tractor',
  'vehicle.management.trailer': 'Trailer',
  'vehicle.management.keyword.search': 'Keyword Search',
  'vehicle.management.basic': 'Basic',
  'vehicle.management.lifecycle': 'Lifecycle',
  'vehicle.management.maintenance.plan': 'Maintenance Plan',
  'vehicle.management.maintenance.todo': 'Pending Todo',
  'vehicle.management.maintenance.inspectReport': 'Inspect Report',
  'vehicle.management.maintenance.vehicleMileage': 'Vehicle Mileage',
  'vehicle.management.maintenance.uncoveredFault': 'Uncovered Fault',
  'vehicle.management.maintenance.snapshot': 'Snapshot',
  'vehicle.management.maintenance.history': 'Maintenance History',
  'vehicle.management.ownership.information': 'Ownership Information',
  'vehicle.management.fleet': 'Fleet',
  'vehicle.management.group': 'Group',
  'vehicle.management.residentCity': 'Resident City',
  'vehicle.management.service.information': 'Service Information',
  'vehicle.management.driver.information': 'Driver Information',
  'vehicle.management.driver': 'Driver',
  'vehicle.management.binding.time': 'Binding Time',
  'vehicle.management.inviter': 'Inviter',
  'vehicle.management.operation': 'Operation',
  'vehicle.management.send.password': 'Send  Password',
  'vehicle.management.unbind': 'Unbind',
  'vehicle.management.unbind.confirm': 'Are you Srue?',
  'vehicle.management.resend.password': 'Resend password',
  'vehicle.management.logged.in': 'Logged In',
  'vehicle.management.registration.info': 'Registration Info',
  'vehicle.management.owner': 'Owner',
  'vehicle.management.usage': 'Usage',
  'vehicle.management.brand.model': 'Brand/Model',
  'vehicle.management.vin': 'VIN',
  'vehicle.management.engine.number': 'Engine number',
  'vehicle.management.registration.date': 'Registration Date',
  'vehicle.management.issue.date': 'Issue Date',
  'vehicle.management.address': 'Address',
  'vehicle.management.record.number': 'Record Number',
  'vehicle.management.authorized.personnel': 'Authorized Personnel',
  'vehicle.management.gross.weight': 'Gross Weight',
  'vehicle.management.curb.weight': 'Curb Weight',
  'vehicle.management.dimensions': 'Dimensions',
  'vehicle.management.gross.combination.weight': 'Gross Combination Weight',
  'vehicle.management.inspection.records': 'Inspection Records',
  'vehicle.management.details': 'Details',
  'vehicle.management.vehicle.model': 'Vehicle Model',
  'vehicle.management.emission.standard': 'Emission standard',
  'vehicle.management.engine.brand': 'Engine Brand',
  'vehicle.management.engine.model': 'Engine Model',
  'vehicle.management.engine.namePlate': 'Nameplate',
  'vehicle.management.fuel.type': 'Fuel type',
  'vehicle.management.axle.configuration': 'Axle Configuration',
  'vehicle.management.transmission.brand': 'Transmission Brand',
  'vehicle.management.transmission.model': 'Transmission Model',
  'vehicle.management.def.pump.brand': 'DEF Pump Brand',
  'vehicle.management.def.pump.model': 'DEF Pump Model',
  'vehicle.management.front.axle.brand': 'Front axle brand',
  'vehicle.management.front.axle.model': 'Front axle model',
  'vehicle.management.middle.axle.brand': 'Rear/Middle Axle Brand',
  'vehicle.management.middle.axle.model': 'Rear/Middle Axle Model',
  'vehicle.management.battery.specs': 'Battery Specs',
  'vehicle.management.repair': 'Repair',
  'vehicle.management.maintain': 'Maintain',
  'vehicle.management.inspect': 'Inspect',
  'vehicle.management.100k.km': '100k km',
  'vehicle.management.full.screen': 'Full screen',
  'vehicle.management.n.times': 'N times',
  'vehicle.management.future.plans': 'Future Plans',
  'vehicle.management.pending': 'Pending',
  'vehicle.management.maintainning': 'Maintainning',
  'vehicle.management.completed': 'Completed',
  'vehicle.management.historical.items': 'Historical Items',
  'vehicle.management.one.year.plan': 'Post-1-Year Maintenance Plan',
  'vehicle.management.report.time': 'Report Time',
  'vehicle.management.maintenance.items': 'Maintenance Items',
  'vehicle.management.odometer.km': 'Odometer (km)',
  'vehicle.management.orderNo': 'Order No.',
  'vehicle.management.maintItem.repair': 'Repair：',
  'vehicle.management.maintItem.accessory': 'Accessory：',
};
