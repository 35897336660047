export default {
  'vehicle.management': '车辆管理',
  'vehicle.management.vehiclePlateNo': '车牌号',
  'vehicle.management.fleetGroup': '车队小组',
  'vehicle.management.coverageType': '承保类型',
  'vehicle.management.coverageType.select': '请选择承保类型',
  'vehicle.management.vehicleBusinessTypes': '业务类型',
  'vehicle.management.vehicleBusinessTypes.select': '请选择业务类型',
  'vehicle.management.serviceManager': '服务经理',
  'vehicle.management.vehicleType': '车辆类型',
  'vehicle.management.vehicleType.select': '请选择车辆类型',
  'vehicle.management.covered': '保内',
  'vehicle.management.uncovered': '保外',
  'vehicle.management.Tractor': '主车',
  'vehicle.management.trailer': '挂车',
  'vehicle.management.keyword.search': '输入关键词模糊检索选择',
  'vehicle.management.basic': '基本',
  'vehicle.management.lifecycle': '生命周期',
  'vehicle.management.maintenance.plan': '养护计划',
  'vehicle.management.maintenance.todo': '待办',
  'vehicle.management.maintenance.inspectReport': '报告',
  'vehicle.management.maintenance.vehicleMileage': '里程',
  'vehicle.management.maintenance.uncoveredFault': '保外故障',
  'vehicle.management.maintenance.snapshot': '日志',
  'vehicle.management.maintenance.history': '维保历史',
  'vehicle.management.ownership.information': '归属信息',
  'vehicle.management.fleet': '车队',
  'vehicle.management.group': '小组',
  'vehicle.management.residentCity': '常驻城市',
  'vehicle.management.service.information': '服务信息',
  'vehicle.management.driver.information': '司机信息',
  'vehicle.management.driver': '司机',
  'vehicle.management.binding.time': '绑车时间',
  'vehicle.management.inviter': '邀请人',
  'vehicle.management.operation': '操作',
  'vehicle.management.send.password': '发送账号密码',
  'vehicle.management.unbind': '解绑',
  'vehicle.management.unbind.confirm': '确定解绑吗？',
  'vehicle.management.resend.password': '重新发送账号密码',
  'vehicle.management.logged.in': '已登录',
  'vehicle.management.registration.info': '行驶证信息',
  'vehicle.management.owner': '所有人',
  'vehicle.management.usage': '使用性质',
  'vehicle.management.brand.model': '品牌型号',
  'vehicle.management.vin': '车辆识别代号（VIN）',
  'vehicle.management.engine.number': '发动机号码',
  'vehicle.management.registration.date': '注册日期',
  'vehicle.management.issue.date': '发证日期',
  'vehicle.management.address': '住址',
  'vehicle.management.record.number': '档案编号',
  'vehicle.management.authorized.personnel': '核定人数',
  'vehicle.management.gross.weight': '总质量',
  'vehicle.management.curb.weight': '整备质量',
  'vehicle.management.dimensions': '外廓尺寸',
  'vehicle.management.gross.combination.weight': '准牵引总质量',
  'vehicle.management.inspection.records': '检验记录',
  'vehicle.management.details': '详细信息',
  'vehicle.management.vehicle.model': '车型',
  'vehicle.management.emission.standard': '排放标准',
  'vehicle.management.engine.brand': '发动机品牌',
  'vehicle.management.engine.model': '发动机型号',
  'vehicle.management.engine.namePlate': '查看铭牌',
  'vehicle.management.fuel.type': '燃油类型',
  'vehicle.management.axle.configuration': '驱动形式',
  'vehicle.management.transmission.brand': '变速箱品牌',
  'vehicle.management.transmission.model': '变速箱型号',
  'vehicle.management.def.pump.brand': '尿素泵品牌',
  'vehicle.management.def.pump.model': '尿素泵型号',
  'vehicle.management.front.axle.brand': '前桥品牌',
  'vehicle.management.front.axle.model': '前桥型号',
  'vehicle.management.middle.axle.brand': '中后桥品牌',
  'vehicle.management.middle.axle.model': '中后桥型号',
  'vehicle.management.battery.specs': '蓄电池规格',
  'vehicle.management.repair': '维修',
  'vehicle.management.maintain': '保养',
  'vehicle.management.inspect': '检查',
  'vehicle.management.100k.km': '万公里',
  'vehicle.management.full.screen': '全屏',
  'vehicle.management.n.times': 'N次',
  'vehicle.management.future.plans': '未来计划',
  'vehicle.management.pending': '待保养',
  'vehicle.management.maintainning': '维保中',
  'vehicle.management.completed': '已完成',
  'vehicle.management.historical.items': '历史触发项目',
  'vehicle.management.one.year.plan': '1年后养护计划',
  'vehicle.management.report.time': '报修时间',
  'vehicle.management.maintenance.items': '维保项目',
  'vehicle.management.odometer.km': '仪表里程（km）',
  'vehicle.management.orderNo': '维保单号',
  'vehicle.management.maintItem.repair': '维修：',
  'vehicle.management.maintItem.accessory': '配件：',
};
