export default {
  'maint.components.sample': 'This is naming sample',
  'maint.components.vehicleInformation': 'Vehicle information',
  'maint.components.vehicleBrandlAbel': 'Brand and model',
  'maint.components.vehicleVinLabel': 'VIN code',
  'maint.components.engineNumberLabel': 'Engine number',
  'maint.components.vehicleMileageItemLabel': 'Mileage',
  'maint.components.faultInformation': 'Fault information',
  'maint.components.faultPhenomenonLabel': 'Fault phenomenon',
  'maint.components.repairInformationLabel': 'Repair information',
  'maint.components.repairTimeLabel': 'Repair time',
  'maint.components.maintenanceLocationLabel': 'Repair location',
  'maint.components.faultReporterLabel': 'Fault reporter',
  'maint.components.serviceManagerLabel': 'Service Manager',
  'maint.components.appointmentTimeLabel': 'Appointment time',
  'maint.components.fleetGroupLabel': 'Fleet group',
  'maint.components.alternateContactsLabel': 'Alternate contacts',
  'maint.components.historicalMaintenance': 'Historical maintenance',
  'maint.components.maintOrderNo': 'Order No',
  'maint.components.jobContent': 'Job content',
  'maint.components.meterMileage': 'Meter mileage',
  'maint.components.completionTime': 'Completion time',
  'maint.components.mileageAccrual': 'Mileage accrual',
  'maint.components.search.placeholder.maintOrderNo':
    'last 5 digits or full number',
  'maint.components.search.placeholder.vehiclePlateNo': 'last 3 or full number',
  'maint.components.search.placeholder.keywordNo': 'Order or Plate No.',
  'maint.components.checkInVehicleMileageItemLabel': 'Checked In Mileage',
  'maint.components.checkInVehicleMileageImgKey': 'Picture',
  'maint.components.clickToView': 'Click to view',
  'maint.components.MARequirementsLabel': 'M&A Requirements',
  'maint.components.originalReceivingDiagram': 'Original receiving diagram',
  'maint.components.repairLocationLabel': 'Repair location',
  'maint.components.standardDispatcherLabel': 'Standard Dispatcher',
  'maint.components.picturePreview': 'Picture Preview',
  'maint.components.checkinOvertime': 'Pick-up timeout (Scheduled {dateTime})',
  'maint.components.checkin.before': 'Pick up the car in front',
  'maint.components.faultName': 'Fault name',
  'maint.components.faultClassification': ' The fault classification',
  'maint.components.typeOfWarranty': 'Type of warranty',
  'maint.components.addTime': 'Add time',
  'maint.components.maintenanceInformation': 'Repair information',
  'maint.components.accessoryName': 'Accessory Name',
  'maint.components.number': 'number',
  'maint.components.maintenanceTimeLabel': 'Repair time',
  'maint.components.estimatedPick-upTime': 'Estimated pick-up time',
  'maint.components.underWarranty': 'Under warranty',
  'maint.components.pitAdministratorLabel': 'Pit Administrator',
  'maint.components.dispatchNotesLabel': 'Dispatch notes',
  'maint.components.pick-upInformationLabel': 'Pick-up information',
  'maint.components.job-picture': 'Job Picture',
  'maint.components.maintenanceWorkerLabel': 'Maintenance worker',
  'maint.components.receivingDiagram': 'Receiving diagram',
  'maint.components.todoItems': 'To-do items',
  'maint.components.workinghourName': 'Work Hours',
  'maint.components.workinghourNumber': 'Number of working hours',
  'maint.components.workinghourCategoryName': 'Category',
  'maint.components.workingHourAllowance': 'Allowance for working hours',
  'maint.components.fittingMaintCostCount': 'Number of accessories',
  'maint.components.otherFees': 'Other fees',
  'maint.components.freightForAccessories':
    'Freight for accessories({currencySymbol})',
  'maint.components.towingFees': 'Towing fees({currencySymbol})',
  'maint.components.venueUsageFee': 'Venue usage fee({currencySymbol})',
  'maint.components.nighttimeSubsidyFee':
    'Nighttime subsidy fee({currencySymbol})',
  'maint.components.fare': 'Fare({currencySymbol})',
  'maint.components.new.vehicle.reminder': 'New car',
  'maint.components.new.station.reminder': 'New Station',
  'maint.components.new.vehicle.service.requirement': 'View Service Request',
  'maint.components.vehicleMileageImg': 'Mileage pictures',
};
